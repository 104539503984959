.App {
  text-align: center;
  font-family: "StackedPixel", system-ui, sans-serif;
  color: #a5e7ff;
}

.paulos-logo {
  max-width: 900px;
  width: 100%;
  opacity: 1;
  margin-top: -40px;
  z-index: 3;
}

.site-sections {
  background-color: #000;
}

.games-logo,
.nfts-logo {
  width: 100%;
  opacity: 1;
  z-index: 3;
}

.text-logo {
  font-family: "StackedPixel";
  position: absolute;
  display: flex;
  color: #a5e7ff;
  font-size: 23px;
  gap: 18px;
  top: 0;
  left: 0;
  opacity: 1;
  padding: 20px;
  margin: 10px;
  z-index: 3;
  cursor: pointer;
}

.text-logo > div:hover {
  text-decoration: underline;
}

.click-to-mint {
  color: white;
  text-decoration: underline;
}

.App-header,
.games,
.nfts {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.chakra-modal__content-container {
  z-index: 100 !important;
}

.games-heading {
  display: flex;
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  justify-content: center;
  font-size: 60px;
}

.nfts-heading {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: 60px;
  margin-bottom: 15%;
  gap: 20px;
}
.nfts-mint {
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.nfts-mint-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 60px;
}
.nfts-mint-heading > iframe {
  border-color: transparent;
}

.play-on-desktop {
  display: none;
  padding: 10px;
}

.game-options {
  display: flex;
  position: absolute;
  gap: 20%;
  z-index: 3;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.game-options > div {
  font-size: 30px;
  max-width: 150px;
  word-wrap: normal;
  border: 2px solid white;
  padding: 6px;
  border-radius: 10px;
  color: white;
  background-color: rgba(178, 104, 255, 0.5);
  cursor: pointer;
}

.game-options > div:hover {
  background-color: rgba(178, 104, 255, 0.9);
}

.nfts-options {
  display: flex;
  position: absolute;
  z-index: 3;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.nfts-options > div {
  font-size: 50px;
  color: white;
  cursor: pointer;
}

/* main background animation */
@keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}

@keyframes move-clouds-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000px 0;
  }
}

.stars,
.twinkling,
.clouds {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.stars {
  background: #000 url(stars.png) repeat top center;
  z-index: 0;
}

.twinkling {
  background: transparent url(twinkling.png) repeat top center;
  z-index: 1;
  animation: move-twink-back 200s linear infinite;
}

.clouds {
  background: transparent url(clouds.png) repeat bottom center;
  z-index: 2;
  opacity: 0.4;
  animation: move-clouds-back 200s linear infinite;
}

/** GAMES **/
.chakra-aspect-ratio > iframe {
  background-color: black;
  border-color: black;
}

.chakra-modal__close-btn {
  display: flex;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  padding: 10px 10px 0px 0px;
}

.footer {
  z-index: 10;
}

.footer > div > a {
  color: #a5e7ff;
}




@media (max-width: 500px) {
  .text-logo {
    font-size: 20px;
    width: 100%;
    display: flex;
    padding: 0px;
    margin: 0px;
    margin-top: 30px;
    margin-left: 30px;
  }
  .text-logo > div:hover {
    text-decoration: none;
  }
  .App-header {
    min-height: 70vh;
  }
  .games,
  .nfts {
    min-height: 0vh;
  }
  .games-logo,
  .nfts-logo {
    width: 100%;
    opacity: 1;
    z-index: 3;
  }
  .games-heading {
    display: none;
  }
  .nfts-heading {
    /*display: none;*/
  }
  .play-on-desktop {
    display: flex;
    flex-direction: column;
    z-index: 3;
    width: 70%;
    justify-content: flex-start;
    align-items: center;
  }
  .game-options {
    display: none;
  }
}