@font-face {
  font-family: "StackedPixel";
  src: local("StackedPixel"), url(./fonts/StackedPixel.ttf) format("truetype");
}

html {
  scroll-behavior: smooth;
  background-color: black;
}

body {
  margin: 0;
  font-family: "StackedPixel", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
